export function buildQueryParamsFromObject<T extends Record<string, string | number[] | string[] | number | null | undefined>>(object: T, useQuestionMark: boolean = true): string {
    let queryString = useQuestionMark ? '?' : '&';

    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    queryString += `${key}=${(value as (number | string)[]).join(',')}&`;
                }
            } else if (value !== '' && value !== null && value !== undefined) {
                queryString += `${key}=${value}&`;
            }
        }
    }

    queryString = queryString.slice(0, -1);

    return queryString;
}
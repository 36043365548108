<div *ngIf="isOpen" @isOpenTrigger class="main">
  <div
    class="backdrop"
    [style.opacity]="backdropOpacity"
    (click)="toggleIsOpen()"
    (keyup)="toggleIsOpen()"
    aria-hidden="true"
  ></div>
  <div class="popup">
    <div
      *ngIf="showCloseButton"
      class="close"
      (click)="toggleIsOpen()"
      (keyup)="toggleIsOpen()"
      aria-hidden="true"
    >
      <img alt="close" src="../../../../assets/icons/close.svg" />
    </div>
    <ng-content></ng-content>
  </div>
</div>

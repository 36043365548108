<div class="search-bar-container">
  <input
    [(ngModel)]="searchTerm"
    type="text"
    placeholder="{{
      'SEARCH_BAR.SEARCH' | translate
    }}"
    (input)="onSearch()"
    class="search-input"
  />

  <img
    src="../../../../assets/icons/search.svg"
    alt="Search"
    class="search-icon"
  />
</div>

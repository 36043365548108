<app-popup
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  [showCloseButton]="false"
>
  <div
    class="confirmation-modal-content"
    [ngStyle]="{ width: popupWidth + 'px' }"
  >
    <h2 class="confirmation-modal-title">
      {{ title | translate }}
    </h2>

    <ng-content></ng-content>

    <div
      class="confirmation-modal-buttons"
      [ngClass]="{
        row: actionContainerStyle === 'row',
        column: actionContainerStyle === 'column',
        center: actionsContainerCentered
      }"
    >
      @for (item of buttonConfigurations; track $index) {
        <app-button
          [variant]="item.variant"
          [color]="item.color"
          [size]="item.size"
          [grow]="item.grow"
          (action)="item.action()"
        >
          {{ item.label | translate }}
        </app-button>
      }
    </div>
  </div>
</app-popup>

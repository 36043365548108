import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TableColumn } from '../../models/table-column';
import {
  AuditStatus,
  PromotionStatus,
  AuditStatusColors,
  PromotionStatusColors,
  Promotion,
} from '../../../api/models/promotion';
import { ONLY_DATE } from '../../constants/date-formats';
import { TableCellColor } from '../../models/table-cell-color';
import { buildQueryParamsFromObject } from '../../../api/helpers/query-params-builder';
import { TableFilters } from '../table/table.component';

@Component({
  selector: 'app-promotions-table',
  templateUrl: './promotions-table.component.html',
  styleUrl: './promotions-table.component.scss',
})
export class PromotionsTableComponent {
  @Input() promotions: Promotion[] = [];
  @Input() editColumn: TableColumn | undefined;

  @Output() filtersChanged = new EventEmitter<string>();

  auditStatusList = this.getStatus(
    Object.values(AuditStatus),
    AuditStatusColors,
    'AUDIT_STATUS',
  );
  promotionsStatusList = this.getStatus(
    Object.values(PromotionStatus),
    PromotionStatusColors,
    'PROMOTION_STATUS',
  );

  promoColumns: TableColumn[] = [
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-ID',
      key: 'id',
      sortable: true,
      sortOptions: {
        ascLabel: 'TABLE.SORTING.TEXT.ASC',
        descLabel: 'TABLE.SORTING.TEXT.DESC',
      },
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-NAME',
      key: 'name',
      sortable: true,
      sortOptions: {
        ascLabel: 'TABLE.SORTING.TEXT.ASC',
        descLabel: 'TABLE.SORTING.TEXT.DESC',
      },
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-TYPE',
      key: 'type',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-COUNTRY',
      key: 'country',
      filterable: true,
      filterQueryParamName: 'country',
    },
    {
      type: 'text',
      label: 'DASHBOARD.PROMO-LANGUAGE',
      key: 'language',
      filterable: true,
      filterQueryParamName: 'language',
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-START-DATE',
      key: 'startDate',
      format: ONLY_DATE,
      sortable: true,
      sortOptions: {
        ascLabel: 'TABLE.SORTING.DATE.ASC',
        descLabel: 'TABLE.SORTING.DATE.DESC',
      },
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-END-DATE',
      key: 'endDate',
      format: ONLY_DATE,
      sortable: true,
      sortOptions: {
        ascLabel: 'TABLE.SORTING.DATE.ASC',
        descLabel: 'TABLE.SORTING.DATE.DESC',
      },
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-EXP-DATE',
      key: 'expirationDate',
      format: ONLY_DATE,
    },
    {
      type: 'date',
      label: 'DASHBOARD.PROMO-AUDIT-DUE-DATE',
      key: 'auditDueDate',
      format: ONLY_DATE,
    },
    {
      type: 'boolean',
      label: 'DASHBOARD.PROMO-AGENCY-STATUS',
      key: 'agencyStatus',
      textTrue: 'DASHBOARD.PROMO-ACTIVE',
      textFalse: 'DASHBOARD.PROMO-INACTIVE',
    },
    {
      type: 'enum',
      label: 'DASHBOARD.PROMO-STATUS',
      key: 'promotionStatus',
      values: this.promotionsStatusList,
      filterable: true,
      filterQueryParamName: 'promotionStatusList',
    },
    {
      type: 'enum',
      label: 'DASHBOARD.PROMO-AUDIT-STATUS',
      key: 'auditStatus',
      values: this.auditStatusList,
      filterable: true,
      filterQueryParamName: 'auditStatusList',
    },
  ];

  getColumns() {
    if (this.editColumn) {
      return [...this.promoColumns, this.editColumn];
    }
    return this.promoColumns;
  }

  getStatus(
    status: string[],
    colors: Record<string, TableCellColor>,
    statusKey: string,
  ): { key: string; value: string; color?: TableCellColor }[] {
    return status.map((key) => ({
      key,
      value: `${statusKey}.${key}`,
      color: colors[key],
    }));
  }

  onTableFiltersChanged(filters: TableFilters): void {
    this.filtersChanged.emit(buildQueryParamsFromObject(filters, true));
  }
}
